function transl_get(path, lang)
{
    let result = lang_data[lang ?? window.lang][path[0]];
    let depth  = 0;

    while(depth < path.length - 1)
    {
        depth++;
        result = result[path[depth]];
    }

    return result;
}


function transl_fill()
{
    let path;

    console.log('Filling with lang ' + lang);

    document.querySelectorAll('[data-transl-val]').forEach(el =>
    {
        path         = el.attributes['data-transl-val'].value.split('-');        
        el.innerHTML = transl_get(path, lang);
    });

    document.querySelectorAll('[data-transl-pla]').forEach(el =>
    {
        path           = el.attributes['data-transl-pla'].value.split('-');
        el.placeholder = transl_get(path, lang);
    });
}


transl_fill();